import React, { useState } from "react";
import { Box, Typography, TextField, IconButton, Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

const CacheDimensionsForm = ({
  handleKeyDown,
  sizeX,
  sizeY,
  sizeZ,
  onSizeXChange,
  //onFrontYChange,
  onRearYChange,
  onSizeZChange,
}) => {
  const [view, setView] = useState("default");
  const [isChanged, setIsChanged] = useState(false);

  const handleClick = () => {
    // Change la vue à "default" seulement si elle n'est pas déjà en "default"
    if (view !== "default") {
      setView("default");
    }
  };

  const handleSizeXChange = (value) => {
    onSizeXChange(value);
    setIsChanged(true);
  };

  const handleSizeYChange = (value) => {
    onRearYChange(value);
    setIsChanged(true);
  };

  const handleSizeZChangeLocal = (value) => {
    onSizeZChange(value);
    setIsChanged(true);
  };

  const renderSideView = () => {
    return (
      <>
        <Box
          component="img"
          src="/types/dimensions-side.png" // Remplace par l'URL de l'image
          alt="Dimensions du cache"
          sx={{ width: "100%", borderRadius: 2 }}
        />

        {/* Close icon */}
        <IconButton
          sx={{
            position: "absolute",
            top: "10px", // Adjust the position as needed
            right: "10px", // Adjust the position as needed
            bgcolor: "black",
            color: "white",
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseIcon sx={{ fontSize: "16px" }} />
        </IconButton>

        {/* angle */}
        <Box
          sx={{
            position: "absolute",
            top: "10%",
            right: "39%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "30px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {"2°"}
          </Typography>
        </Box>

        {/* jointure haute variable */}
        <Box
          sx={{
            position: "absolute",
            top: "14%",
            right: "18%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "40px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {Math.ceil(80 + sizeZ * Math.tan((2 * Math.PI) / 180))}
          </Typography>
        </Box>

        {/* ecart mur */}
        <Box
          sx={{
            position: "absolute",
            top: "48%",
            right: "33%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "30px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {85}
          </Typography>
        </Box>

        {/* hauteur variable */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: "18%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "40px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {sizeY - 2 * 80 - Math.ceil(sizeZ * Math.tan((2 * Math.PI) / 180))}
          </Typography>
        </Box>

        {/* jointure bas */}
        <Box
          sx={{
            position: "absolute",
            bottom: "13%",
            right: "18%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "30px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {80}
          </Typography>
        </Box>
      </>
    );
  };

  const renderTopView = () => {
    return (
      <>
        <Box
          component="img"
          src="/types/dimensions-top.png" // Remplace par l'URL de l'image
          alt="Dimensions du cache"
          sx={{ width: "100%", borderRadius: 2 }}
        />

        {/* Close icon */}
        <IconButton
          sx={{
            position: "absolute",
            top: "10px", // Adjust the position as needed
            right: "10px", // Adjust the position as needed
            bgcolor: "black",
            color: "white",
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseIcon sx={{ fontSize: "16px" }} />
        </IconButton>

        {/* jointure 1 */}
        <Box
          sx={{
            position: "absolute",
            top: "14%",
            right: "24%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "30px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {80}
          </Typography>
        </Box>

        {/* ecart mur */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: "37%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "30px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {85}
          </Typography>
        </Box>

        {/* longueur variable */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: "24%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "40px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {sizeX - 80 * 2}
          </Typography>
        </Box>

        {/* jointure 2 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "9%",
            right: "24%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "30px",
            height: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {80}
          </Typography>
        </Box>
      </>
    );
  };

  const renderZoomView = () => {
    return (
      <>
        <Box
          component="img"
          src="/types/dimensions-zoom.png" // Remplace par l'URL de l'image
          alt="Dimensions du cache"
          sx={{ width: "100%", borderRadius: 2 }}
        />

        {/* Close icon */}
        <IconButton
          sx={{
            position: "absolute",
            top: "10px", // Adjust the position as needed
            right: "10px", // Adjust the position as needed
            bgcolor: "black",
            color: "white",
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseIcon sx={{ fontSize: "16px" }} />
        </IconButton>
      </>
    );
  };

  const renderDefaultView = () => {
    return (
      <>
        {/* Image représentant le cache */}
        <Box
          component="img"
          src="/types/default-dimensions.png" // Remplace par l'URL de ton image
          alt="Dimensions du cache"
          sx={{ width: "100%", borderRadius: 2 }}
        />

        {/* Largeur (sizeX) */}
        <Box
          sx={{
            position: "absolute",
            bottom: "22%", // Ajuster pour positionner au bon endroit
            left: "36%", // Centré horizontalement
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "white",
            width: "80px",
            height: "50px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <TextField
            value={sizeX}
            onChange={(e) => handleSizeXChange(e.target.value)}
            onKeyDown={handleKeyDown} // Ajout de la gestion des touches
            variant="outlined"
            size="small"
            sx={{ width: "100%", textAlign: "center" }}
            inputProps={{
              style: { textAlign: "center", padding: "5px" },
              inputMode: "numeric", // Limite l'input aux chiffres
              pattern: "[0-9]*", // Accepte uniquement les chiffres
            }}
          />
        </Box>

        {/* Hauteur avant */}
        <Box
          sx={{
            position: "absolute",
            top: "35%",
            left: "-10%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2c8998",
            color: "white",
            width: "80px",
            height: "50px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {Math.trunc(sizeY - sizeZ * Math.tan((2 * Math.PI) / 180))}
          </Typography>
        </Box>

        {/* Profondeur (sizeZ) */}
        <Box
          sx={{
            position: "absolute",
            bottom: "13%",
            right: "10%",
            transform: "translateX(-50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "white",
            width: "80px",
            height: "50px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <TextField
            value={sizeZ}
            onChange={(e) => handleSizeZChangeLocal(e.target.value)}
            onKeyDown={handleKeyDown} // Ajout de la gestion des touches
            variant="outlined"
            size="small"
            sx={{ width: "100%", textAlign: "center" }}
            inputProps={{
              style: { textAlign: "center", padding: "5px" },
              inputMode: "numeric", // Limite l'input aux chiffres
              pattern: "[0-9]*", // Accepte uniquement les chiffres
            }}
          />
        </Box>

        {/* Hauteur arrière (sizeY)*/}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: "10%",
            transform: "translate(50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "white",
            width: "80px",
            height: "50px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <TextField
            value={sizeY}
            onChange={(e) => handleSizeYChange(e.target.value)}
            onKeyDown={handleKeyDown} // Ajout de la gestion des touches
            variant="outlined"
            size="small"
            sx={{ width: "100%", textAlign: "center" }}
            inputProps={{
              style: { textAlign: "center", padding: "5px" },
              inputMode: "numeric", // Limite l'input aux chiffres
              pattern: "[0-9]*", // Accepte uniquement les chiffres
            }}
          />
        </Box>

        {/* Infobulles flottantes */}
        <IconButton
          sx={{
            position: "absolute",
            top: "20%",
            left: "40%",
            transform: "translate(-50%, -50%)",
            color: "#1976d2",
          }}
          onClick={() => setView("top")} // Changement de vue
        >
          <InfoIcon fontSize="large" />
        </IconButton>

        <IconButton
          sx={{
            position: "absolute",
            bottom: "45%",
            right: "30%",
            transform: "translate(50%, 50%)",
            color: "#1976d2",
          }}
          onClick={() => setView("side")} // Changement de vue
        >
          <InfoIcon fontSize="large" />
        </IconButton>

        {/* Nouvelle infobulle pour le zoom */}
        <IconButton
          sx={{
            position: "absolute",
            top: "38%",
            right: "45%",
            transform: "translate(50%, -50%)",
            color: "#1976d2",
          }}
          onClick={() => setView("zoom")} // Changement de vue vers zoom
        >
          <InfoIcon fontSize="large" />
        </IconButton>
      </>
    );
  };

  return (
    <Box
      onClick={handleClick} // Ajout de l'événement onClick ici
      sx={{
        position: "relative",
        textAlign: "center",
        mt: 4,
        width: "100%",
        maxWidth: "400px", // Max width pour l'image
        mx: "auto", // Centrer horizontalement
        cursor: view === "default" ? "default" : "pointer",
      }}
    >
      {view === "default" && renderDefaultView()}
      {view === "side" && renderSideView()}
      {view === "top" && renderTopView()}
      {view === "zoom" && renderZoomView()}

      {/* Bouton pour reinitialiser */}
      {isChanged && (
        <Button
          variant="contained"
          // onClick={handleResetClick}
          sx={{
            fontFamily: "'Fira Sans', Helvetica, Arial, Lucida, sans-serif",
            color: "#5CB1E2",
            boxShadow: "none !important",
            border: "1px solid transparent",
            fontSize: "12px",
            marginTop: { xs: "15px", md: 0 },
            borderRadius: 10,
            backgroundColor: "#efefef",
            "&:hover": {
              backgroundColor: "#efefef",
              border: "1px solid #5CB1E2",
            },
          }}
        >
          Réinitialiser les valeurs
        </Button>
      )}
    </Box>
  );
};

export default CacheDimensionsForm;
