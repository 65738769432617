import React from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ShoppingCart,
  ExpandMore,
  PlayCircleOutline,
  Menu as MenuIcon,
} from "@mui/icons-material";

function ResponsiveAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleDiscoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDiscoverClose = () => {
    setAnchorEl(null);
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const discoverLinks = [
    {
      label: "Notre Histoire",
      url: "https://mycover-up.fr/decouvrir-coverup/#notrehistoire",
    },
    { label: "Nos réalisations", url: "https://mycover-up.fr/nos-realisations/" },
    {
      label: "Nos clients",
      url: "https://mycover-up.fr/decouvrir-coverup/#nosclients#",
    },
    {
      label: "Notre processus de fabrication des caches clim en aluminium",
      url: "https://mycover-up.fr/le-composite-aluminium-le-materiau-de-nos-caches/",
    },
    {
      label: "Procédé de fabrication de nos caches",
      url: "https://mycover-up.fr/procede-de-fabrication-de-nos-caches/",
    },
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const drawer = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      <List>
        <ListItem>
          <Button
            variant="contained"
            color="primary"
            href="https://my-cover-up.kapsloc.fr/"
            sx={{
              backgroundColor: "#007AC3",
              borderRadius: "20px",
              color: "#fff",
              textTransform: "none",
              width: "100%",
              "&:hover": {
                backgroundColor: "#0069d9",
              },
            }}
          >
            Créer mon cache
          </Button>
        </ListItem>
        <ListItem>
          <Button
            variant="contained"
            href="https://mycover-up.fr/support-mycover-up/#montage"
            sx={{
              backgroundColor: "#e0e0e0",
              borderRadius: "20px",
              color: "#000",
              textTransform: "none",
              width: "100%",
              "&:hover": {
                backgroundColor: "#d5d5d5",
              },
            }}
          >
            Monter mon cache
            <PlayCircleOutline sx={{ color: "#007bff", ml: 0.5 }} />
          </Button>
        </ListItem>
        <ListItem>
          <ListItemText primary="Découvrir myCover'Up" />
        </ListItem>
        {discoverLinks.map((link) => (
          <ListItem
            component="a"
            href={link.url}
            key={link.label}
            sx={{ pl: 4, "&:visited": { color: "#007bff" } }}
          >
            <ListItemText primary={link.label} />
          </ListItem>
        ))}
        <Divider />
        <ListItem  component="a" sx={{"&:visited": { color: "#0000" }}} href="https://mycover-up.fr/mon-compte/">
          <ListItemText primary="Mon compte" />
        </ListItem>
        <ListItem  component="a" sx={{"&:visited": { color: "#0000" }}} href="https://mycover-up.fr/contact/">
          <ListItemText primary="Contact" />
        </ListItem>
        <ListItem  component="a" sx={{"&:visited": { color: "#0000" }}} href="https://mycover-up.fr/panier/">
          <IconButton sx={{ color: "#007bff" }}>
            <Badge badgeContent={0} color="secondary">
              <ShoppingCart />
            </Badge>
          </IconButton>
          <ListItemText primary="Panier" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "white", color: "black" }}>
      <Toolbar
        disableGutters
        sx={{
          display: "flex",
          alignItems: "center",
          minHeight: { xs: "7.6vh", md: "8.5vh" },
          justifyContent: "center",
          position: "relative",
        }}
      >
        {isMobile ? (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 2,
            }}
          >
            <Box
              component="a"
              href="/"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src="/logo-horizontal-final-scaled.jpg"
                alt="Logo"
                sx={{
                  height: { xs: "6vh", sm: "7vh", md: "8vh" },
                  maxWidth: "150px",
                  objectFit: "contain",
                }}
              />
            </Box>

            {/*burger */}
            <IconButton
              edge="end"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        ) : (
          <Box
            component="a"
            href="/"
            sx={{
              position: "absolute",
              left: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src="/logo-horizontal-final-scaled.jpg"
              alt="Logo"
              sx={{
                height: { xs: "6vh", sm: "7vh", md: "8vh" },
                maxWidth: "250px",
                objectFit: "contain",
              }}
            />
          </Box>
        )}

        {!isMobile && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            {/*Créer mon cache */}
            <Button
              variant="contained"
              color="primary"
              href="https://my-cover-up.kapsloc.fr/"
              sx={{
                backgroundColor: "#007AC3",
                borderRadius: "20px",
                color: "#fff",
                boxShadow: "none !important",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#0069d9",
                  boxShadow: "none !important",
                },
              }}
            >
              Créer mon cache
            </Button>

            {/* Monter mon cache */}
            <Button
              variant="contained"
              href="https://mycover-up.fr/support-mycover-up/#montage"
              sx={{
                backgroundColor: "#f4f4f4",
                borderRadius: "20px",
                color: "#000",
                boxShadow: "none !important",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#d5d5d5",
                  boxShadow: "none !important",
                },
              }}
            >
              Monter mon cache
              <PlayCircleOutline sx={{ color: "#007bff", ml: 0.5 }} />
            </Button>

            {/* Menu déroulant "Découvrir myCover'Up" */}
            <Button
              onClick={handleDiscoverClick}
              endIcon={<ExpandMore />}
              sx={{
                textTransform: "none",
                color: "#000",
              }}
            >
              Découvrir myCover'Up
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleDiscoverClose}
              MenuListProps={{
                "aria-labelledby": "discover-button",
              }}
            >
              {discoverLinks.map((link) => (
                <MenuItem
                  key={link.label}
                  onClick={handleDiscoverClose}
                  component="a"
                  href={link.url}
                  sx={{
                    "&:hover": {
                      color: "#007bff",
                    },
                  }}
                >
                  {link.label}
                </MenuItem>
              ))}
            </Menu>

            {/* Bouton "Mon compte" */}
            <Button
              href="https://mycover-up.fr/mon-compte/"
              sx={{
                textTransform: "none",
                color: "#000",
              }}
            >
              Mon compte
            </Button>

            {/* Bouton "Contact" */}
            <Button
              href="https://mycover-up.fr/contact/"
              sx={{
                textTransform: "none",
                color: "#000",
              }}
            >
              Contact
            </Button>

            {/* Icône du panier */}
            <IconButton
              href="https://mycover-up.fr/panier/"
              sx={{ color: "#007bff" }}
            >
              <Badge badgeContent={0} color="secondary">
                <ShoppingCart />
              </Badge>
            </IconButton>
          </Box>
        )}
      </Toolbar>

      {/* Drawer pour le menu mobile */}
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
}

export default ResponsiveAppBar;
