import React, { useEffect, useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";

// Styled button
const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: 50,
  backgroundColor: "#f0c300",
  color: "grey",
  borderRadius: "50px",
  border: "1px solid #e5e1e1",
  fontWeight: "bold",
  textTransform: "none",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px",
  "&:hover": {
    backgroundColor: "#007ac2",
    color: "white",
  },
  "&:disabled": {
    backgroundColor: "#E0E0E0",
    color: "#A0A0A0",
    opacity: "0.5",
  },

  [theme.breakpoints.down("md")]: {
    transform: "translateY(-15px)",
  },
}));

function roundToTwo(num) {
  return +(Math.round(num + "e+2")  + "e-2");
}

const AddToCartButton = ({ price = 500, isDisabled, quotationDatas }) => {
  const [askForQuotation, setAskForQuotation] = useState(false);
  const ttcPrice =  roundToTwo(price * (1 + (20 / 100)))
  useEffect(() => {
    setAskForQuotation(quotationDatas.isNeeded);
  }, [quotationDatas.isNeeded]);

  const renderPriceButton = () => (
    <StyledButton
      disabled={isDisabled}
      href="/panier/"
    >
      <Box
        sx={{
          height: "100%",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          opacity: "1",
          border: "1px solid #e5e1e1",
          borderRadius: "50px",
          minWidth: "80px",
          padding: "5px",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            flexGrow: 5,
            color: "#007ac2",
            fontWeight: "bold",
            fontSize: { xs: '0.8rem', sm: '1rem', md: '0.7rem', lg: '0.85rem'},
          }}
        >
          {ttcPrice}€ TTC
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            flexGrow: 3,
            color: "#000",
            fontSize: { xs: '0.6rem', sm: '0.8rem', md: '0.5rem', lg: '0.7rem'},
          }}
        >
          {price}€ HT
        </Typography>
      </Box>
      <Typography
        sx={{
          textAlign: "center",
          flexGrow: 1,
          fontSize: { xs: '1rem', sm: '1rem', md: '0.70rem', lg: '1rem'},
          fontWeight: "bold",
          color: "black",
          whiteSpace: "nowrap", // Prevent text wrapping
          overflow: "hidden",
          textOverflow: "ellipsis",
          minWidth: "90px",
        }}
      >
        Ajouter au panier
      </Typography>
    </StyledButton>
  );

  const renderQuotationButton = () => (
    <Box
      sx={{
        width: "100%",
        height: "50px",
        backgroundColor: "#007ac2",
        border: "1px solid #e5e1e1",
        borderRadius: "50px",
        color: "white",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "clamp(12px, 2vw, 18px)", // Responsive text size
        "&:hover": {
          backgroundColor: "white",
          color: "#007ac2",
        },
      }}
    >
      <Typography>Demander un devis</Typography>
    </Box>
  );

  return (
    <>
      {!askForQuotation && renderPriceButton()}
      {askForQuotation && renderQuotationButton()}
    </>
  );
};

export default AddToCartButton;
