import React from "react";
import { Box, Typography, Button } from "@mui/material";

const DiverBar = () => {
  return (
    <Box
      sx={{
        fontFamily: "'Montserrat', Helvetica, Arial, Lucida, sans-serif",
        backgroundColor: "#007ac3",
        padding: {
          xs: "20px 10px",
          sm: "30px 20px",
          md: "40px",
        },
        color: "white",
        paddingBottom: {
          xs: "10px",
          sm: "120px",
          md: "170px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          maxWidth: "1200px",
          margin: "0 auto",
          flexWrap: "wrap",
        }}
      >
        {/* Colonne 1 */}
        <Box
          sx={{
            flex: 1,
            margin: {
              xs: "10px 5px",
              sm: "10px 10px",
              md: "0 20px",
            },
            minWidth: "200px",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography variant="h5" sx={{ fontSize: "1.2em", marginBottom: "10px", fontFamily: "'Montserrat', Helvetica, Arial, Lucida, sans-serif", }}>
            Cover'Up
          </Typography>
          <Typography sx={{ fontSize: "0.9em", margin: "5px 0" }}>
            14 rue des frères Peugeot,
          </Typography>
          <Typography sx={{ fontSize: "0.9em", margin: "5px 0", fontFamily: "'Montserrat', Helvetica, Arial, Lucida, sans-serif", }}>31130 Balma</Typography>
        </Box>

        {/* Colonne 2 */}
        <Box
          sx={{
            flex: 1,
            margin: {
              xs: "10px 5px",
              sm: "10px 10px",
              md: "0 20px",
            },
            minWidth: "200px",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography variant="h5" sx={{ fontSize: "1.2em", marginBottom: "10px", fontFamily: "'Montserrat', Helvetica, Arial, Lucida, sans-serif", }}>
            Navigation
          </Typography>
          <Box component="ul" sx={{ listStyleType: "none", padding: 0 }}>
            {["Créer mon cache Clim", "Notre Marque", "Nos réalisations", "Contact"].map(
              (item, index) => (
                <Box
                  component="li"
                  key={index}
                  sx={{
                    marginBottom: "5px",
                    fontSize: "0.9em",
                    cursor: "pointer",
                    "&:hover": { textDecoration: "underline" },
                    fontFamily: "'Montserrat', Helvetica, Arial, Lucida, sans-serif",
                  }}
                >
                  {item}
                </Box>
              )
            )}
          </Box>
        </Box>

        {/* Colonne 3 */}
        <Box
          sx={{
            flex: 1,
            margin: {
              xs: "10px 5px",
              sm: "10px 10px",
              md: "0 20px",
            },
            minWidth: "200px",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography variant="h5" sx={{ fontSize: "1.2em", marginBottom: "10px", fontFamily: "'Montserrat', Helvetica, Arial, Lucida, sans-serif", }}>
            Liens Utiles
          </Typography>
          <Box component="ul" sx={{ listStyleType: "none", padding: 0 }}>
            {[
              "Mentions légales",
              "Politique de confidentialité",
              "CGV",
              "Expédition & Remboursements",
            ].map((item, index) => (
              <Box
                component="li"
                key={index}
                sx={{
                  marginBottom: "5px",
                  fontSize: "0.9em",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline" },
                  fontFamily: "'Montserrat', Helvetica, Arial, Lucida, sans-serif",
                }}
              >
                {item}
              </Box>
            ))}
          </Box>
        </Box>

        {/* Colonne 4 */}
        <Box
          sx={{
            flex: 1,
            margin: {
              xs: "10px 5px",
              sm: "10px 10px",
              md: "0 20px",
            },
            minWidth: "200px",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography variant="h5" sx={{ fontSize: "1.2em", marginBottom: "10px",fontFamily: "'Montserrat', Helvetica, Arial, Lucida, sans-serif", }}>
            Une Question ?
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "white",
              color: "#007ac3",
              padding: "10px 20px",
              borderRadius: "50px",
              fontWeight: "bold",
              cursor: "pointer",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#005a8d",
                color: "white",
              },
              marginTop: { xs: "10px", md: "0" },
              fontFamily: "'Montserrat', Helvetica, Arial, Lucida, sans-serif",
            }}
          >
            Contactez-nous
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DiverBar;
