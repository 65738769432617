import createModelContext from "../threejs/modelRenderer";
import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Button, Snackbar } from "@mui/material";
import ConfiguratorForm from "../Components/ConfigurationsSteps/ConfiguratorForm";
import ConfiguratorMenu from "../Components/Stepper/ConfiguratorMenu";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

window.ModelContext = {
  create: createModelContext,
};

const Configurator = () => {
  const contentRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [modelFormData, setModelFormData] = useState({
    pattern: "55",
    type: "sol",
    sizeX: 940,
    sizeY: 705,
    sizeZ: 480,
    sizeL: 850,
    sizeH: 650,
    sizeP1: 400,
    sizeP2: 400,
    bottom: false,
  });
  const [materialFormData, setMaterialFormData] = useState({
    material: "9",
  });

  const [quotationData, setQuotationData] = useState({
    isNeeded: false,
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [price, setPrice] = useState(0);
  const [bearer, setBearer] = useState("");
  const [patterns, setPatterns] = useState([]);
  const [textures, setTextures] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Importation des hooks
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Create 3D model and material context on component mount
  useEffect(() => {
    const { update3DModel, update3DMaterial } = window.ModelContext.create(
      contentRef.current
    );
    window.ModelContext.update3DModel = update3DModel;
    window.ModelContext.update3DMaterial = update3DMaterial;
    fetchAndUpdateToken();
  }, []);

  // Fetch patterns and textures when bearer token is set
  useEffect(() => {
    if (!!bearer && bearer.length > 0) {
      fetchPatternsAndTextures();
    }
  }, [bearer]);

  const fetchPatternsAndTextures = async () => {
    setPatterns(await fetchPatterns());
    setTextures(await fetchTextures());
  };

  const fetchAndUpdateToken = async () => {
    setBearer(await fetchBearerToken());
  };

  const fetchBearerToken = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: process.env.REACT_APP_SERVICE_NAME,
            apiKey: process.env.REACT_APP_BACKEND_API_KEY,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch bearer token");
      }

      const data = await response.json();
      return data?.accessToken;
    } catch (error) {
      console.error("Error fetching bearer token:", error);
    }
  };

  const fetchPatterns = async () => {
    try {
      const bearer = await fetchBearerToken();
      if (!bearer) return;

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/patterns`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${bearer}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch patterns");
      }

      const resPatterns = await response.json();
      return resPatterns;
    } catch (error) {
      console.error("Error fetching patterns:", error);
    }
  };

  const fetchTextures = async () => {
    try {
      const bearer = await fetchBearerToken();
      if (!bearer) return;

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/materials`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      console.log(response)
      if (!response.ok) {
        throw new Error("Failed to fetch textures");
      }

      const resTextures = await response.json();
      const formattedTextures = formatTextures(resTextures);

      return formattedTextures;
    } catch (error) {
      console.error("Error fetching textures:", error);
    }
  };

  const formatTextures = (txs) => {
    const colors = {
      brillants: [],
      mats: [],
      métal: [],
      bois: [],
    };

    txs.forEach((tx) => {
      colors[tx.materialType].push(tx);
    });

    return [
      {
        id: "brillants",
        name: "Brillants",
        colors: colors.brillants,
      },
      {
        id: "mats",
        name: "Mats",
        colors: colors.mats,
      },
      {
        id: "metal",
        name: "Métal",
        colors: colors["métal"],
      },
      {
        id: "bois",
        name: "Bois",
        colors: colors.bois,
      },
    ];
  };

  const calculatePrice = () => {
    const { sizeL, sizeH, sizeP1, sizeP2, bottom } = modelFormData;
    const Surface = (sizeL * sizeP1 + sizeL * sizeH + 2 * sizeH * sizeP1 + 132 * sizeL + 145 * sizeH + 92 * sizeP1 + 12881 ) / 1000000;
    console.log(Surface)
    let price = 115 * Surface - 8.5 * Math.pow(Surface, 2);
    console.log(price)
    
    if (bottom) {
      price *= 1.125;
    }

    price +=25 //frais de port
    return price; // Limite à deux décimales
  };

  // Update 3D model when modelFormData changes
  useEffect(() => {
    const { pattern, sizeX, sizeY, sizeZ, bottom, type } = modelFormData;
    const { material } = materialFormData;
    const update3DModel = window.ModelContext.update3DModel;

    setPrice(calculatePrice().toFixed(2))
    // Update 3D model with new dimensions
    // (Dimensions are swapped because of the orientation of the model)
    update3DModel({
      size: {
        x: Number(sizeZ),
        y: Number(sizeX),
        z: Number(sizeY),
      },
      bottom,
      material,
      pattern,
      type,
    }).catch((err) => console.error("err", err));
  }, [modelFormData]);

  // Update 3D material when materialFormData changes
  useEffect(() => {
    const { material } = materialFormData;
    const update3DMaterial = window.ModelContext.update3DMaterial;
    update3DMaterial(material).catch((err) => console.error("err", err));
    console.log("update3DMaterial", material)
  }, [materialFormData]);

  const handleModelInfosChange = (key, value) => {
    setModelFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleMaterialInfosChange = (key, value) => {
    setMaterialFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleStepChange = (newStep) => {
    setCurrentStep(newStep);
  };

  const handleQuotationInfosChange = (key, value) => {
    setQuotationData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // ici logique cache
  const generateShareableURL = () => {
    const baseUrl = window.location.origin + window.location.pathname;
    const params = new URLSearchParams();

    for (const [key, value] of Object.entries(modelFormData)) {
      params.append(key, value);
    }

    for (const [key, value] of Object.entries(materialFormData)) {
      params.append(key, value);
    }

    const shareableURL = `${baseUrl}?${params.toString()}`;
    return shareableURL;
  };

  const handleShareCache = async () => {
    const url = generateShareableURL();
    try {
      await navigator.clipboard.writeText(url);
      setSnackbarOpen(true);
    } catch (err) {
      console.error("Échec de la copie : ", err);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      contentRef.current.requestFullscreen().catch((err) => {
        console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      document.exitFullscreen();
    }
    setIsFullscreen(!isFullscreen);
  };

  return (
    <>
      {/* Configurator Menu - Sticky at top */}
      <ConfiguratorMenu
        price={price}
        currentStep={currentStep}
        onStepChange={handleStepChange}
        quotationDatas={quotationData}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          pt: { xs: 2, md: 8 },
        }}
      >
        {/* 3D Model Viewer */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: { xs: "100%", md: "50vw" },
          }}
        >
          {/* Three.js */}
          <Box
            ref={contentRef}
            sx={{
              width: "100%",
              height: { xs: "40vh", sm: "50vh", md: "70vh" },
              backgroundColor: "#ffffff",
              position: { md: "sticky" },
              top: { md: 0 },
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* box bouton et made in france */}
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 16px",
                zIndex: 3,
              }}
            >
              {/* made in france */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src="../images/Infos/france-coq.png"
                  alt="Fabriqué en France"
                  sx={{
                    width: { xs: 30, sm: 40, md: 50 },
                    height: { xs: 30, sm: 40, md: 50 },
                    mb: 1,
                  }}
                />
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{
                    fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" },
                  }}
                >
                  Fabriqué en France
                </Typography>
              </Box>

              {/* bouton partager cache */}
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    fontFamily:
                      "'Fira Sans', Helvetica, Arial, Lucida, sans-serif",
                    color: "#5CB1E2",
                    boxShadow: "none !important",
                    border: "1px solid transparent",
                    fontSize: "12px",
                    marginTop: { xs: "15px", md: 0 },
                    borderRadius: 10,
                    backgroundColor: "#efefef",
                    "&:hover": {
                      backgroundColor: "#efefef",
                      border: "1px solid #5CB1E2",
                    },
                  }}
                  onClick={handleShareCache}
                >
                  Partager mon cache
                </Button>
              </Box>
            </Box>
          </Box>
          {/* Box de debug */}
          <Box
            id="gui_debug"
            sx={{ display: "none", position: "absolute", top: 0, zIndex: 3 }}
          />

          {/*"Voir mon cache" */}

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            message="Lien copié dans le presse-papiers !"
          />

          {/* Affichage conditionnel du logo 360*/}
          {!isMobile && (
            <>
              {/*Image */}
              <Box
                component="img"
                src="../images/Infos/360-usipanel.png"
                alt="logo-360"
                sx={{
                  width: 70,
                  height: 70,
                  mt: 3,
                }}
              />

              {/* Texte */}
              {/* <Typography
                align="center"
                sx={{
                  m: 4,
                  fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" },
                  fontFamily:
                    "'Montserrat', Helvetica, Arial, Lucida, sans-serif",
                }}
              >
                Prévisualisez et faites pivoter le modèle grâce à notre
                technologie 3D haute fidélité.
              </Typography> */}
            </>
          )}

          <Typography
            align="center"
            sx={{
              m: 4,
              fontSize: { xs: "0.60rem", sm: "0.875rem", md: "1rem" },
              fontFamily: "'Montserrat', Helvetica, Arial, Lucida, sans-serif",
            }}
          >
            Prévisualisez et faites pivoter le modèle grâce à notre technologie
            3D haute fidélité.
          </Typography>

          
        </Box>

        {/* Configurator Form */}
        <Box
          sx={{ width: { xs: "100%", md: "50vw" }, padding: { xs: 2, md: 4 } }}
        >
          <ConfiguratorForm
            step={currentStep}
            patterns={patterns}
            textures={textures}
            modelFormData={modelFormData}
            materialFormData={materialFormData}
            quotationData={quotationData}
            onModelFormChange={handleModelInfosChange}
            onMaterialFormChange={handleMaterialInfosChange}
            onQuotationChange={handleQuotationInfosChange}
            handleStepChange={handleStepChange}
          />
        </Box>
      </Box>
    </>
  );
};

export default Configurator;
