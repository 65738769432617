import React from 'react';
import { Box, Typography } from '@mui/material';

function BadgeWidget() {
  return (
    <Box
      id="sag_b_cocarde"
      sx={{
        position: { xs: 'relative', md: 'fixed' },
        top: { xs: "0", sm: "13.9%", md: "13.5%" },
        left: { xs: '0', md: '0' },
        zIndex: 10,
        cursor: 'pointer',
        display: 'block',
        width: { xs: 65, sm: 75, md: 125 },
        height: { xs: 75, sm: 90, md: 134 },
        backgroundImage:
          'url(https://www.societe-des-avis-garantis.fr/wp-content/plugins/ag-core/images/widgets/cocarde.png)',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        margin: '5px',
        transformOrigin: '0% 100%',
        '@media (min-width: 900px)': {
          transform: 'scale(0.95)',
        },
      }}
    >
      <Box
        className="container"
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          margin: 0,
          backgroundColor: 'unset',
          textAlign: 'center',
          fontFamily: "'Oswald', Arial",
        }}
      >
        <Typography
          component="div"
          sx={{
            position: 'absolute',
            top: { xs: 20, sm: 25, md: 45 }, // Position du texte principal
            left: 0,
            width: '100%',
            fontSize: { xs: 7, sm: 7, md: 15 }, // Taille de la police responsive
            fontWeight: 700,
            color: '#ffffff',
            lineHeight: { xs: '18px', sm: '20px', md: '22px' },
            letterSpacing: 0,
          }}
        >
          <big>9.7</big>/10
        </Typography>
        <Typography
          className="sagInfo"
          sx={{
            position: 'absolute',
            top: { xs: 34, sm: 40, md: 66 }, // Position du texte des avis
            left: 0,
            width: '100%',
            fontSize: { xs: '6.3px', sm: '7px', md: '10.5px' }, // Taille de la police responsive
            color: '#ffffff',
            letterSpacing: 0,
          }}
        >
          311 avis
        </Typography>
      </Box>
    </Box>
  );
}

export default BadgeWidget;
