import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ralToHex } from "../../threejs/modelRenderer";

const TextureForm = ({ selectedMaterial, onMaterialChange, textures }) => {
  const [selectedCategory, setSelectedCategory] = useState("brillants");
  const [selectedColor, setSelectedColor] = useState(null);
  const theme = useTheme();

  // Détecter les breakpoints
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const selectedMaterialData = textures.find(
    (material) => material.id === selectedCategory
  );

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedColor(null);
  };

  const handleColorChange = (colorId) => {
    const color = selectedMaterialData.colors.find((c) => c.id === colorId);
    setSelectedColor(color);
    onMaterialChange(colorId);
  };

  return (
    <Box sx={{ paddingRight: "20px", paddingLeft: "20px", paddingTop: isXs ? "25px" : "0", }}>
      <Typography variant="h3" gutterBottom align="center" sx={{ fontSize: { xs: '1rem', sm: '1.5rem', md: '2rem', lg: '2rem'},}}>
        Texture
      </Typography>

      {selectedColor && selectedColor.showWarningMessage && (
        <Box
          sx={{
            textAlign: "center",
            padding: "10px",
            marginBottom: "20px",
            background: "#fffbdc",
            borderRadius: "40px",
            width: "100%",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: "100%",
              fontFamily: "Fira Sans, Helvetica, Arial, Lucida, sans-serif",
            }}
          >
            Texture déconseillée en cas de forte exposition au soleil
          </Typography>
        </Box>
      )}

      {/* Boutons des catégories de matériaux */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap", // Permet aux boutons de passer à la ligne si nécessaire
          gap: "10px",
          mb: 3,
        }}
      >
        {textures.map((material) => (
          <Button
            key={material.id}
            onClick={() => handleCategoryChange(material.id)}
            sx={{
              padding: isXs ? "5px 10px" : "10px 50px", // Réduire le padding en mode mobile
              minWidth: 0, // Permettre aux boutons de se réduire en largeur
              height: "40px",
              textTransform: "none",
              backgroundColor:
                selectedCategory === material.id ? "#007ac3" : "white",
              color: selectedCategory === material.id ? "white" : "black",
              border: "1px solid #007ac3",
              borderRadius: "50px",
              transition: "background-color 0.3s, color 0.3s",
              "&:hover": {
                backgroundColor:
                  selectedCategory === material.id ? "#005a8d" : "#007ac3",
                color: "white",
              },
              flex: "1 1 auto", // Permettre aux boutons de s'étirer ou de se contracter
              fontSize: isXs ? "0.8rem" : "1rem", // Réduire la taille de la police en mode mobile
            }}
          >
            {material.name}
          </Button>
        ))}
      </Box>

      {/* Affichage des textures/couleurs pour la catégorie sélectionnée */}
      {selectedMaterialData && (
        <Box
          sx={{
            display: "flex",
            flexWrap: isXs ? "nowrap" : "wrap",
            overflowX: isXs ? "auto" : "visible",
            justifyContent: isXs ? "normal" : "center",
            gap: 3,
          }}
        >
          {selectedMaterialData.colors.map((color) => (
            <CardActionArea
              key={color.id}
              onClick={() => handleColorChange(color.id)}
              sx={{
                borderRadius: 2,
                border:
                  selectedMaterial === color.id
                    ? "2px solid #1976d2"
                    : "1px solid #e0e0e0",
                transition: "box-shadow 0.3s ease-in-out, transform 0.2s",
                width: 120,
                height: 120,
                flex: "0 0 auto",
                position: "relative",
              }}
            >
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 2,
                  overflow: "hidden",
                  backgroundColor: color.ralColorId ? ralToHex(color.ralColorId) : "transparent", // Apply the converted color if ralColorId is not null
                }}
              >
              </Card>

              {/* Affichage du nom et du code RAL */}
              {selectedMaterial === color.id && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#B3B3B3",
                    // Optionally, add text shadow for better visibility
                    textShadow: "0px 0px 3px rgba(0,0,0,0.1)",
                  }}
                >
                  <Typography variant="caption">
                    {color.name} {color.code && `(${color.code})`}
                  </Typography>
                </Box>
              )}
            </CardActionArea>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default TextureForm;
