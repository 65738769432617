import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

export default function FreeShippingBanner() {
  const texts = [
    "Frais de port offerts sur toutes les commandes !",
    "N'attendez pas pour commander !"
  ];

  const [currentText, setCurrentText] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentText((prevText) => (prevText + 1) % texts.length);
        setFade(true);
      }, 1000); // Durée du fade-out (doit correspondre à la durée de transition CSS)
    }, 5000); // Change le texte toutes les 5 secondes

    return () => clearInterval(interval);
  }, [texts.length]);

  return (
    <Box sx={{ backgroundColor: "#5cb1e2", padding: "10px 0", marginTop: { xs: "7.7vh", sm:"8vh", md:"8.5vh" } , textAlign: "center" }}>
      <Typography
        component="h3"
        sx={{
          color: "white",
          fontWeight: "bold",
          whiteSpace: "nowrap",
          fontSize: { xs: "13px", sm: "17px" }, // Responsive fontSize
          opacity: fade ? 1 : 0,
          transition: "opacity 1s ease-in-out",
        }}
      >
        {texts[currentText]}
      </Typography>
    </Box>
  );
}
