import * as React from 'react';
import { Typography, Paper, Container, Box } from '@mui/material';

function Footer() {
    return (
        <Paper
            sx={{
                width: '100%',
                bottom: 0,
                height: {
                    xs: '13vh', // iPhone 14 et Galaxy S23
                    sm: '10.5vh', // Galaxy Tab A9
                    md: '8vh',  // iPad
                    lg: '10vh', // Desktop
                },
                p: {
                    xs: 1,
                    sm: 2,
                    md: 3,
                },
                textAlign: 'center',
            }}
            component="footer"
            square
            variant="outlined"
        >
            <Container>
                <Box
                    sx={{
                        flexGrow: 1,
                        justifyContent: "center",
                        display: "flex",
                        my: 1,
                    }}
                >
                </Box>

                <Box
                    sx={{
                        flexGrow: 1,
                        justifyContent: "center",
                        display: "flex",
                        mb: 2,
                    }}
                >
                    <Typography variant="caption" color="initial">
                        © 2024 – Usipanel
                    </Typography>
                </Box>
            </Container>
        </Paper>
    );
}

export default Footer;
