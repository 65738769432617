import {
  MeshPhysicalMaterial,
  TextureLoader,
  RepeatWrapping,
  Vector2,
} from "three";
import queryString from "query-string";
import createWebContext from "./webContext.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import colours from "./colours.json";
import { addObjectToScene, removeObjectFromScene } from "./renderer";

let bearerToken = null;

//#region Core

/**
 * @param {any} content TODO
 * @returns {update3DObject} Renvoie la méthode setModel utilisable pour update l'objet 3D en fonction de paramètres
 */
const createModelContext = (content) => {
  const { updateObject, updateMaterial } = createWebContext(content);
  let lastPromise = null;
  let abortController = null; // Permet d'annuler les requêtes en cours
  let previousParams = null; // Permet de comparer les paramètres actuels avec les précédents
  let faces = null; // Permet de savoir quelles faces vont être chargées

  // Appelé à chaque fois qu'on intéragit avec le configurateur sur la page
  const update3DModel = (params) => {
    console.log("updateObject", params);

    // Vérification des modifications des paramètres et sélection des faces à charger
    if (
      previousParams !== null &&
      JSON.stringify(params) === JSON.stringify(previousParams)
    ) {
      faces = [];
    } else if (
      previousParams !== null &&
      params.pattern !== previousParams.pattern
    ) {
      faces = ["left", "right", "front"];

      // Annule les requêtes en cours
      if (abortController !== null) {
        abortController.abort();
      }
      // Créer un nouvel abortController pour les nouvelles requêtes
      abortController = new AbortController();
    } else {
      faces = ["left", "right", "front", "top"];

      // Annule les requêtes en cours
      if (abortController !== null) {
        abortController.abort();
      }
      // Créer un nouvel abortController pour les nouvelles requêtes
      abortController = new AbortController();
    }

    if (
      previousParams !== null &&
      params.type == "facade-haut" &&
      params.bottom === true
    ) {
      faces.push("bottom");
    } else if (
      (previousParams !== null && params.type != "facade-haut") ||
      params.bottom === false
    ) {
      removeObjectFromScene("bottom");
    }

    previousParams = params;

    // Récupère le signal pour les requêtes
    const { signal } = abortController;

    // Fonction pour mettre à jour l'objet 3D et instantier un waiter (retour visuel de chargement)
    return updateObject(async () => {
      let lastComputedObject = null; // Permet de stocker le dernier objet calculé

      // Créer un tableau de promesses pour le téléchargement de chaque face
      const promises = faces.map((face) => {
        const label = `[Face] fetchFaceData-${face}`;
        console.time(label); // Démarrer le chronomètre pour cette face
        // Récupération des données de la face
        return fetchFaceData(
          {
            ...params,
            size: params.size,
            signal,
          },
          face
        )
          .then((fetchedData) => {
            console.timeEnd(label); // Arrêter le chronomètre pour cette face

            let object = fetchedData.object.scene; // Stockage de l'objet 3D
            let material = fetchedData.material; // Stockage du material

            // Calcul de l'objet 3D avec le material
            const computedObject = generateComputedObject({
              object,
              material: material,
            });

            // Ajouter l'objet à la scène
            addObjectToScene(computedObject, face);

            // Stocker l'objet calculé
            lastComputedObject = computedObject;
          })
          .catch((error) => {
            console.timeEnd(label); // Arrêter le chronomètre en cas d'erreur
            console.error("Failed to fetch face data:", error);
          });
      });

      // Parallélisation des promesses (requêtes pour chaque face)
      lastPromise = Promise.allSettled(promises);

      // Attendre que toutes les promesses soient résolues
      await lastPromise;

      // Retourner la taille pour la réinitialisation de la caméra
      return {
        object: lastComputedObject,
        size: params.size,
      };
    });
  };

  const update3DMaterial = (materialId) => {
    return updateMaterial(materialId);
  };

  return {
    update3DModel,
    update3DMaterial,
  };
};

//#endregion Core

//#region Generation

/**
 * @param {any} materialInfos contient les informations du material à créer
 * Si une normal map est défini alors on l'a télécharge
 * @returns {MeshPhysicalMaterial} Renvoie le material instancié
 */
export const generateComputedMaterial = async (materialInfos) => {
  // Conversion de "shininess" en "roughness" pour MeshPhysicalMaterial
  // Utiliser des limites raisonnables pour garder un bon rendu
  const roughness = Math.min(1, Math.max(0, 1 - materialInfos.shininess / 600)); // Limiter roughness entre 0 et 1
  let metalness = 0; // Toujours à 0 pour des matériaux non métalliques
  if (materialInfos.shininess > 300) metalness = 0.1;
  // Créer les informations pour le matériau
  const computedMaterialInfos = {
    color: materialInfos.color, // Maintenir la couleur pure, pas de transformation ici
    emissive: computeHexNumber(materialInfos.emissive),
    emissiveIntensity: 1,
    roughness: roughness,
    metalness: metalness,
    clearcoat: materialInfos.shininess > 300 ? 1 : 0, // Activer clearcoat si brillant
    clearcoatRoughness: materialInfos.shininess > 300 ? 0.1 : 1, // Rugosité de clearcoat pour brillant
    normalScale: new Vector2(
      materialInfos.normalIntensity *
        (materialInfos.shininess > 300 ? 0.5 : 1.5),
      materialInfos.normalIntensity *
        (materialInfos.shininess > 300 ? 0.5 : 1.5)
    ),
  };
  if (
    "normalMapUrl" in materialInfos &&
    materialInfos.normalMapUrl !== undefined
  ) {
    computedMaterialInfos.texture = await fetchTexture(
      materialInfos.normalMapUrl
    );
  }
  return new MeshPhysicalMaterial(
    computedMaterialInfos /*cubeMaterialSettings*/
  );
};

/**
 * @param {any} object l'objet 3D sur lequel on doit appliquer le material
 * @param {any} material le material à appliquer sur l'objet 3D
 * @returns {any} Renvoie l'objet avec le material appliqué
 */
const generateComputedObject = ({ object, material }) => {
  object.rotateX(-Math.PI * 0.5);
  const applyMaterial = (object) => {
    if (object.type === "Mesh") {
      object.material = material;
      object.castShadow = true; // Le cube projette des ombres
      object.receiveShadow = true;
    }
    object.children.forEach(applyMaterial);
  };

  applyMaterial(object);
  return object;
};

//#endregion

//#region Network
/**Cette méthode permet de récupérer les données de la face (objet 3D et material) en fonction des paramètres.
 * @param {any} params contient les paramètres de la face à charger
 * @param {any} face contient la face à charger
 * @returns {any} Renvoie la face générée par la méthode fetchFaceGltf, et le material généré par la méthode generateComputedMaterial
 */
const fetchFaceData = async (params, face) => {
  try {
    console.log("fetchFaceData", params);
    const materialInfos =
      "materialData" in params
        ? params.materialData
        : await fetchMaterialInfo(params.material);
    const [object, material] = await Promise.all([
      fetchFaceGltf(params, materialInfos, face),
      generateComputedMaterial(materialInfos),
    ]);

    return {
      object,
      material,
    };
  } catch (error) {
    console.error("Error fetching face data:", error);
    throw error;
  }
};
//#endregion Network

//#region GLTF Download

/**Cette méthode permet de setup de manière claire la requête pour récupérer les données de la face.
 * @param {any} params contient les paramètres de la face à charger
 * @param {any} materialInfos contient les informations du material à créer
 * @param {any} face contient la face à charger
 * @returns {any} Renvoie la réponse de la requête pour récupérer la face générée par l'OTS
 */
const getFetchFaceResponse = (params, materialInfos, face) => {
  const { signal } = params;
  if ("pattern" in params) {
    const queryStringified = queryString.stringify({
      sizeX: params.size.x,
      sizeY: params.size.y,
      sizeZ: params.size.z,
      pattern: params.pattern,
      bottom: params.bottom ? "true" : "false",
      textureSize: materialInfos.textureSize,
      face: face,
    });
    console.log(materialInfos);
    const faceUrl = `${process.env.REACT_APP_OTS_URL}/process/obj-face?${queryStringified}`;

    return fetch(faceUrl, { signal });
  } else {
    const queryStringified = queryString.stringify({
      type: params.type,
      sizeX: params.size.x,
      sizeY: params.size.y,
      sizeZ: params.size.z,
      bottom: params.bottom ? "true" : "false",
      textureSize: materialInfos.textureSize,
      renderType: params.renderType,
      cropStrategy: params.cropStrategy,
      face: face,
    });

    const faceUrl = `${process.env.REACT_APP_OTS_URL}/process/obj-face-pattern?${queryStringified}`;

    return fetch(faceUrl, {
      method: "POST",
      headers: {
        "Content-Type": "text/html",
      },
      body: params.patternData,
      signal,
    });
  }
};

/**Cette méthode s'occupe d'envoyer une requête pour récupérer les données de la face.
 * @param {any} params contient les paramètres de la face à charger
 * @param {any} materialInfos contient les informations du material à créer
 * @param {any} face contient la face à charger
 * @returns {any} Renvoie les données de la face générée par l'OTS
 */
const fetchFaceGltf = async (params, materialInfos, face) => {
  const response = await getFetchFaceResponse(params, materialInfos, face);

  if (response.body === null) {
    throw new Error("Empty response");
  } else {
    const buffer = await response.arrayBuffer();

    const loader = new GLTFLoader();

    return new Promise((resolve, reject) => {
      loader.parse(
        buffer,
        "",
        (gltf) => {
          resolve(gltf);
        },
        reject
      );
    });
  }
};

//#endregion GLTF Download

//#region Texture Download

/**Cette méthode permet de télécharger une texture depuis le backend.
 * @param {any} url contient l'url de la texture à télécharger
 * @returns {any} Renvoie la texture téléchargée
 */
const fetchTexture = async (url) => {
  const loader = new TextureLoader();

  if (bearerToken === null) {
    bearerToken = await getBearerToken();
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch texture");
  }

  const blob = await response.blob();

  const texture = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      loader.load(reader.result, resolve, undefined, reject);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });

  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping;

  return texture;
};

/**Cette méthode permet de récupérer les informations d'un material depuis le backend.
 * @param {any} materialId contient l'id du material à récupérer
 * @returns {any} Renvoie les informations du material
 */
export const fetchMaterialInfo = async (materialId) => {
  console.log("fetch material info : ", materialId);
  try {
    if (bearerToken === null) {
      bearerToken = await getBearerToken();
    }

    const response = await fetch(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/materials/search?id=${encodeURIComponent(materialId)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Material not found");
    }

    const materialData = await response.json();
    console.log(materialData);
    const material = {
      color:
        materialData.colorType === "RAL"
          ? ralToHex(materialData.ralColorId)
          : computeHexNumber(materialData.color),

      emissive: materialData.emissive,

      specular: materialData.specular,
      shininess: materialData.shininess,

      normalIntensity: materialData.normalIntensity,

      textureSize: materialData.textureSize,

      //colorMapUrl: materialData.colorMapUrl,

      //normalMapUrl: materialData.normalMapUrl
    };
    return material;
  } catch (error) {
    console.error("Error fetching material info:", error);
    throw error;
  }
};

//#endregion Texture Download

//#region Auth

/**Cette méthode permet de récupérer un bearer token depuis le backend pour y effectuer des requêtes.
 * @returns {any} Renvoie le bearer token
 */
const getBearerToken = async () => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: process.env.REACT_APP_SERVICE_NAME,
      apiKey: process.env.REACT_APP_BACKEND_API_KEY,
    }),
  });
  return response.json().then((data) => data.accessToken);
};

//#endregion Auth

//#region Helpers

const computeHexNumber = (value) => {
  return Number(`0x${value}`);
};

const ralToHex = (ral) => {
  if (!ral || !colours[ral]) {
    console.error(`Unknown RAL code: '${ral}'`);
    return "#FFFFFF"; // Default color if RAL code is invalid
  } else {
    return colours[ral];
  }
};
//#endregion Helpers

export { ralToHex }; // Export de la méthode ralToHex
export default createModelContext;
