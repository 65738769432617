import React, { useState } from "react";
import DimensionsForm from "./DimensionsForm";
import PatternForm from "./PatternForm";
import TypeForm from "./TypeForm";
import TextureForm from "./TextureForm";
import { FormButtonNext } from "../Stepper/FormButtonNext";
import { FormButtonPrevious } from "../Stepper/FormButtonPrevious";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ConfiguratorForm = ({
  step,
  modelFormData,
  materialFormData,
  patterns,
  textures,
  onModelFormChange,
  onMaterialFormChange,
  onQuotationChange,
  handleStepChange,
}) => {
  const [dimensionsErrorString, setDimensionsErrorString] =
    useState("Attention");
  const theme = useTheme();

  // Détecter les différents breakpoints
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  // Déterminer le nombre maximum d'étapes en fonction du breakpoint
  let maxStep;
  if (isXs) {
    maxStep = 2;
  } else if (isSm || isMd) {
    maxStep = 3;
  } else {
    maxStep = 3;
  }

  const handlePatternChange = (event) => {
    onModelFormChange("pattern", event.target.value);
  };

  const handleMaterialChange = (material) => {
    onMaterialFormChange("material", material);
  };

  const handleTypeChange = (type) => {
    onModelFormChange("type", type);
  };

  const handleBottomFaceChange = (isChecked) => {
    onModelFormChange("bottom", isChecked);
  };

  const handleLChange = (L) => {
    if (
      !handleQuotationNeed(
        L,
        modelFormData.sizeH,
        modelFormData.sizeP1,
        modelFormData.sizeP2
      )
    ) {
      handleXChange(L * 10 + 90);
    }
    onModelFormChange("sizeL", L);
  };

  const handleHChange = (H) => {
    onModelFormChange("sizeH", H);
    if (
      !handleQuotationNeed(
        modelFormData.sizeL,
        H,
        modelFormData.sizeP1,
        modelFormData.sizeP2
      )
    ) {
      let tan2Degrees = Math.tan((2 * Math.PI) / 180);
      let rearHeight = Math.ceil(
        H * 10 + 40 + modelFormData.sizeZ * tan2Degrees
      );
      handleRearYChange(rearHeight);
    }
  };

  const handleP1Change = (P1, sizeP2) => {
    onModelFormChange("sizeP1", P1);
    if (
      !handleQuotationNeed(modelFormData.sizeL, modelFormData.sizeH, P1, sizeP2)
    ) {
      if (P1 > sizeP2) {
        handleZChange(P1 * 10 + 80);
      }
    }
  };

  const handleP2Change = (P2, sizeP1) => {
    onModelFormChange("sizeP2", P2);
    if (
      !handleQuotationNeed(modelFormData.sizeL, modelFormData.sizeH, sizeP1, P2)
    ) {
      if (P2 > sizeP1) {
        handleZChange(P2 * 10 + 30);
      }
    }
  };

  const handleXChange = (X) => {
    onModelFormChange("sizeX", X);
  };

  const handleRearYChange = (Y) => {
    onModelFormChange("sizeY", Y);
  };

  const handleZChange = (Z) => {
    let tan2Degrees = Math.tan((2 * Math.PI) / 180);
    let frontHeight = Math.trunc(
      modelFormData.sizeY - modelFormData.sizeZ * tan2Degrees
    );
    let rearHeight = Math.ceil(frontHeight + Z * tan2Degrees);
    onModelFormChange("sizeZ", Z);
    handleRearYChange(rearHeight);
  };

  // Validation des dimensions et création de la chaîne d'erreur
  const handleQuotationNeed = (
    L = modelFormData.sizeL,
    H = modelFormData.sizeH,
    P1 = modelFormData.sizeP1,
    P2 = modelFormData.sizeP2
  ) => {
    let errorString = "Attention";
    let issueNumber = 0;

    if (L > 126) {
      errorString += ", la largeur maximale (1350 mm)";
      issueNumber += 1;
    }

    if (H > 141) {
      errorString += ", la hauteur maximale (1450 mm)";
      issueNumber += 1;
    }

    if (P1 > 77 || P2 > 82) {
      errorString += ", la profondeur maximale (850 mm)";
      issueNumber += 1;
    }

    let singleOrMultiple =
      issueNumber > 1 ? "sont trop grandes" : "est trop grande";
    let needQuotation = issueNumber > 0;

    if (needQuotation) {
      errorString +=
        " de votre cache " +
        singleOrMultiple +
        " pour notre configurateur. Contactez-nous pour obtenir un devis.";
    }

    onQuotationChange("isNeeded", needQuotation);
    setDimensionsErrorString(errorString);
    return needQuotation;
  };

  // Déterminer l'affichage des étapes en fonction du breakpoint
  const renderSteps = () => {
    if (isXs) {
      if (step === 0) {
        return (
          <>
            <PatternForm
              selectedPattern={modelFormData.pattern}
              onPatternChange={handlePatternChange}
              patterns={patterns}
            />
            <TextureForm
              selectedMaterial={materialFormData.material}
              onMaterialChange={handleMaterialChange}
              textures={textures}
            />
          </>
        );
      } else if (step === 1) {
        return (
          <TypeForm
            selectedType={modelFormData.type}
            onTypeChange={handleTypeChange}
            bottomFace={modelFormData.bottom}
            onBottomFaceChange={handleBottomFaceChange}
          />
        );
      } else if (step === 2) {
        return (
          <DimensionsForm
            installationType={modelFormData.type}
            sizeL={modelFormData.sizeL}
            sizeH={modelFormData.sizeH}
            sizeP1={modelFormData.sizeP1}
            sizeP2={modelFormData.sizeP2}
            sizeX={modelFormData.sizeX}
            sizeY={modelFormData.sizeY}
            sizeZ={modelFormData.sizeZ}
            onSizeLChange={handleLChange}
            onSizeHChange={handleHChange}
            onSizeP1Change={handleP1Change}
            onSizeP2Change={handleP2Change}
            onSizeXChange={handleXChange}
            onRearYChange={handleRearYChange}
            onSizeZChange={handleZChange}
            errorString={dimensionsErrorString}
          />
        );
      }
    } else {
      // Pour sm, md, lg, xl
      if (step === 0) {
        return (
          <PatternForm
            selectedPattern={modelFormData.pattern}
            onPatternChange={handlePatternChange}
            patterns={patterns}
          />
        );
      } else if (step === 1) {
        return (
          <TextureForm
            selectedMaterial={materialFormData.material}
            onMaterialChange={handleMaterialChange}
            textures={textures}
          />
        );
      } else if (step === 2) {
        return (
          <TypeForm
            selectedType={modelFormData.type}
            onTypeChange={handleTypeChange}
            bottomFace={modelFormData.bottom}
            onBottomFaceChange={handleBottomFaceChange}
          />
        );
      } else if (step === 3) {
        return (
          <DimensionsForm
            installationType={modelFormData.type}
            sizeL={modelFormData.sizeL}
            sizeH={modelFormData.sizeH}
            sizeP1={modelFormData.sizeP1}
            sizeP2={modelFormData.sizeP2}
            sizeX={modelFormData.sizeX}
            sizeY={modelFormData.sizeY}
            sizeZ={modelFormData.sizeZ}
            onSizeLChange={handleLChange}
            onSizeHChange={handleHChange}
            onSizeP1Change={handleP1Change}
            onSizeP2Change={handleP2Change}
            onSizeXChange={handleXChange}
            onRearYChange={handleRearYChange}
            onSizeZChange={handleZChange}
            errorString={dimensionsErrorString}
          />
        );
      }
    }
  };

   return (
    <div>
      {renderSteps()}

      <div
        style={{
          mt: 4,
          display: "flex",
          marginTop: "50px",
          justifyContent: "space-evenly",
          paddingTop: "50px",
        }}
      >
        {step > 0 && (
          <FormButtonPrevious
            onPrevStep={handleStepChange}
            currentStep={step}
          />
        )}
        {step < maxStep && (
          <FormButtonNext onNextStep={handleStepChange} currentStep={step} />
        )}
      </div>
    </div>
  );
};

export default ConfiguratorForm;
