import React from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepButton, Box } from "@mui/material";
import AddToCartButton from "./AddToCartButton"; // Import du composant AddToCartButton

// Custom connector for the Stepper
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25,
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    backgroundColor: "#ccc",
    borderRadius: 1,
  },
}));

// Step Icon Styling
const ColorlibStepIconRoot = styled("div")(({ ownerState }) => ({
  backgroundColor: "#f1f1f1",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  border: "2px solid #ccc",
  cursor: "pointer",
  transition: "border-color 0.3s ease",
  ...(ownerState.active && {
    borderColor: "#007ac3",
  }),
  "&:hover": {
    borderColor: "#007ac3",
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  // Définir les icônes pour les étapes avec condition pour l'étape 2 en mode mobile
  const icons = {
    1: "/icons/motifs.png",
    2: isXs ? "/icons/climatisation-2.png" : "/icons/texture.png", // TypeForm icon for mobile
    3: isXs ? "/icons/choix.png" : "/icons/climatisation-2.png",
    4: "/icons/choix.png",
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      <img
        src={icons[String(icon)]}
        alt={`Étape ${icon}`}
        style={{ width: "24px", height: "24px" }}
      />
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const ConfiguratorMenu = ({ currentStep, onStepChange, quotationDatas, price }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  // Adapter les étapes en fonction du breakpoint (mobile ou non)
  const steps = isXs
    ? ["Motif & Texture", "Type", "Dimensions"]
    : ["Motif", "Texture", "Type", "Dimensions"];

  const handleStep = (newStep) => {
    console.log("Go to step : ", newStep);
    onStepChange(newStep);
  };

   return (
    <>
      <Box
        sx={{
          left: "45vw",
          transform: isXs ? "translate(-50%, 0%)" : "translate(-30%, 0%)",
          display: "flex",
          justifyContent: isXs ? "center" : "flex-end",
          alignItems: "center",
          width: "50vw",
          position: "sticky",
          top: isXs ? "12vh" : "16.5vh",
          zIndex: 999,
          padding: "10px 0",
        }}
      >
        {/* Section du Stepper */}
        <Stack
          sx={{
            flexGrow: isXs ? "unset" : "5", // Largeur sur écrans moyens et grands
            display: "flex",
            justifyContent: "center",
            width: isXs ? "90%" : "auto", // Largeur plus compacte pour mobile
          }}
          spacing={isXs ? 2 : 4} // Espacement réduit entre étapes sur mobile
        >
          <Stepper
            nonLinear
            activeStep={currentStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton color="inherit" onClick={() => handleStep(index)}>
                  <StepLabel StepIconComponent={(props) => (
                    <ColorlibStepIcon {...props} icon={index + 1} />
                  )}></StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Stack>

        {/* Afficher le bouton pour grand écran */}
        <Box
          sx={{
            flexGrow: "2",
            display: { xs: "none", md: "flex" },
          }}
        >
          <AddToCartButton price={price} quotationDatas={quotationDatas} />
        </Box>
      </Box>

      {/* Afficher le bouton en bas pour petit écran */}
      <Box
        sx={{
          display: { xs: "block", md: "none" },
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          zIndex: 999,
        }}
      >
        <AddToCartButton price={price} quotationDatas={quotationDatas} />
      </Box>
    </>
  );
};

export default ConfiguratorMenu;