import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CacheDimensionsForm from "./CacheDimensionsForm"; // Import du sous-composant

const DimensionsForm = ({
  installationType,
  sizeL,
  sizeH,
  sizeP1,
  sizeP2,
  sizeX,
  sizeY,
  sizeZ,
  onSizeLChange,
  onSizeHChange,
  onSizeP1Change,
  onSizeP2Change,
  onSizeXChange,
  onRearYChange,
  onSizeZChange,
  errorString,
}) => {
  const [showCacheDimensions, setShowCacheDimensions] = useState(false);

  const installationImages = {
    "facade-haut": {
      src: "/types/facade-dimensions.png",
      label: "Mesurer mon unité sur façade",
    },
    "facade-sol": {
      src: "/types/facade-dimensions.png",
      label: "Mesurer mon unité proche du sol",
    },
    sol: {
      src: "/types/sol-dimensions.png",
      label: "Mesurer mon unité au sol",
    },
  };

  const handleShowCacheDimensionsChange = (event) => {
    setShowCacheDimensions(event.target.checked);
  };

  const handleKeyDown = (event) => {
    if (
      !/[0-9]/.test(event.key) &&
      !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(event.key)
    ) {
      event.preventDefault();
    }
  };

  const renderTextField = (label, value, onChange, maxValue, disabled = false) => (
    <TextField
      label={`${label} (cm)`}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      error={value > maxValue}
      onKeyDown={handleKeyDown}
      InputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
      }}
      disabled={disabled}
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: '25px',
          '& fieldset': {
            borderColor: '#007AC3 !important;',
          },
          '&:hover fieldset': {
            borderColor: '#007AC3 !important;',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#007AC3 !important;',
          },
        },
      }}
    />
  );

  return (
    <Box sx={{ padding: "20px", textAlign: "center" }}>
      <Typography variant="h3" gutterBottom align="center" sx={{ mb:1, fontSize: { xs: '1rem', sm: '1.5rem', md: '2rem', lg: '2rem'},}}>
        Dimensions de votre installation
      </Typography>

      {installationImages[installationType] && (
        <Box sx={{ textAlign: "center", mb: 3 }}>
          <Typography variant="body1" gutterBottom sx={{ color: "#007AC3", fontWeight: "bold", fontSize: { xs: '12px', sm: '12px', md: '15px', lg: '15px',} }}>
            {installationImages[installationType].label}
          </Typography>
          <Box
            component="img"
            src={installationImages[installationType].src}
            alt={installationImages[installationType].label}
            sx={{ width: "100%", maxWidth: "400px", borderRadius: 2 }}
          />
        </Box>
      )}

      {errorString !== "Attention" && (
        <Box
          sx={{
            backgroundColor: "#ff7e78",
            color: "white",
            pt: 1,
            pb: 1,
            pl: 3,
            pr: 3,
            m: 2,
            borderRadius: "50px",
            fontSize: "large",
          }}
        >
          {errorString}
        </Box>
      )}

      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={6} sm={3}>
          {renderTextField("L", sizeL, onSizeLChange, 126, true)}
        </Grid>
        <Grid item xs={6} sm={3}>
          {renderTextField("H", sizeH, onSizeHChange, 141, true)}
        </Grid>
        <Grid item xs={6} sm={3}>
          {renderTextField(
            "P1",
            sizeP1,
            (val) => onSizeP1Change(val, sizeP2),
            77,
            true
          )}
        </Grid>
        <Grid item xs={6} sm={3}>
          {renderTextField(
            "P2",
            sizeP2,
            (val) => onSizeP2Change(val, sizeP1),
            82,
            true
          )}
        </Grid>
      </Grid>

      <FormControlLabel
        control={
          <Checkbox
            checked={showCacheDimensions}
            onChange={handleShowCacheDimensionsChange}
            color="primary"
          />
        }
        label="Voir les dimensions de mon cache"
        sx={{ mt: 3, display: "flex", justifyContent: "center" }}
      />

      {showCacheDimensions && (
        <CacheDimensionsForm
          handleKeyDown={handleKeyDown}
          sizeX={sizeX}
          sizeY={sizeY}
          sizeZ={sizeZ}
          onSizeXChange={onSizeXChange}
          onRearYChange={onRearYChange}
          onSizeZChange={onSizeZChange}
        />
      )}
    </Box>
  );
};

export default DimensionsForm;
